.modal-container-wrapper {
  width: 100%;
  /* height: 57vh; */
  flex-shrink: 0;
  padding: 15px;
}

.title-modal {
  text-align: center;
  margin: auto;
}

.logo-modal {
  text-align: center;
}

.wrapper-modal-wallet {
  display: flex;
  margin-bottom: 20px;
}

.sub-title-modal {
  border-radius: 10px;
  border: 1px solid #0d1615;
  background: #fed3e7;
  box-shadow: 1px 1px 0px 0px #0d1615;
  display: flex;
  width: 207px;
  height: 59px;
  padding: 17px 16px 17px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  margin-bottom: 20px !important;
  margin: auto;
}

.continue-wallet {
  margin: 0;
}

.wrap-input-continue {
  width: 100%;
  display: inline-flex;
  align-items: center;
}